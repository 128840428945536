import React, { useState } from "react";
import "styles/CategoriesNavBar/FilterSideBar.css";
import Slider from "react-slider";
import { BiMenuAltLeft } from "react-icons/bi";

const FilterSideBar = ({ filters, handleCheckboxFilter, selectedFilters }) => {
  return (
    <>
      <div className="filterDesktop">
        <div className="FilterSideBar mt-3">
          <div class="card">
            <header class="card-header p-lg-3 p-2">
              <h5 class="title d-flex justify-content-start fw-bold m-0">
                Filter Products
              </h5>
            </header>
            {filters?.map((filterItem) => (
              <article className="card-group-item" key={filterItem.id}>
                <div className="filter-content">
                  <div className="card-body">
                    <form>
                      <span className="d-flex justify-content-start fw-bold pb-3">
                        {filterItem.filter_name}
                      </span>

                      {filterItem?.filter_values?.map((valueItem) => (
                        <label
                          className="form-check d-flex gap-xl-4 gap-lg-3 gap-2"
                          key={valueItem.id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={valueItem.filter_value}
                            value={valueItem.filter_value}
                            checked={selectedFilters[
                              filterItem.filter_column
                            ]?.includes(valueItem.filter_value)}
                            onChange={(e) =>
                              handleCheckboxFilter(
                                filterItem.filter_column,
                                valueItem.filter_value,
                                e.target.checked
                              )
                            }
                          />
                          <span className="form-check-label">
                            {valueItem.filter_value}
                          </span>
                        </label>
                      ))}
                    </form>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>

      <div className="Mobile_filter_bar">
        <div class="offcanvas offcanvas-start bg-transparent border-0" id="filter_sideBar">
          <div className="FilterSideBar">
            <div class="card">
              <header class="card-header p-lg-3 p-2 d-flex justify-content-between">
                <h5 class="title d-flex justify-content-start fw-bold m-0">
                  Filter Products
                </h5>
                <button
                  type="button"
                  class="btn-close btn-close-dark"
                  data-bs-dismiss="offcanvas"
                ></button>
              </header>
              {filters?.map((filterItem) => (
                <article className="card-group-item" key={filterItem.id}>
                  <div className="filter-content">
                    <div className="card-body">
                      <form>
                        <span className="d-flex justify-content-start fw-bold pb-3">
                          {filterItem.filter_name}
                        </span>
                        {filterItem?.filter_values?.map((valueItem) => (
                          <label
                            className="form-check d-flex gap-xl-4 gap-lg-3 gap-2"
                            key={valueItem.id}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={valueItem.filter_value}
                              value={valueItem.filter_value}
                              checked={selectedFilters[
                                filterItem.filter_column
                              ]?.includes(valueItem.filter_value)}
                              onChange={(e) =>
                                handleCheckboxFilter(
                                  filterItem.filter_column,
                                  valueItem.filter_value,
                                  e.target.checked
                                )
                              }
                            />
                            <span className="form-check-label">
                              {valueItem.filter_value}
                            </span>
                          </label>
                        ))}
                      </form>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>

        {/* filter Toggle -------------------------------------------------- */}
      
          <BiMenuAltLeft
            className="fs-4 filter_offcanvasToggle"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#filter_sideBar"
          />
      </div>
    </>
  );
};

export default FilterSideBar;
