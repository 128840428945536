import React, { useEffect, useState } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import "styles/DetailPage/DetailPage.css";
import ImageSlider from "Sections/Detailpage/ImageSlider";
import Table from "Sections/Detailpage/Table";
import { useParams } from "react-router-dom";
import { RecentlyViewProduct, singleProductApi } from "Apis/MainApis";
import DetailRecentView from "Sections/Detailpage/DetailRecentView";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCompareLength } from "../redux/slice/compareLengthSlice.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";
import OffCanvasExample from "Utils/BottomComparison";

const DetailPage = () => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productid = pid.split("_")[1];
  const userId = useSelector((state) => state?.user?.data?.id);
  const [changeOffcanvas, setChangeOffCnavas] = useState(false);
  const [imageSlider, setImageSlider] = useState("");
  const [productId, setProductId] = useState("");
  const [manufacturerEmail, setManufacturerEmail] = useState("");
  const [wishlist, setWishlistChange] = useState(false);
  const [singleProduct, setSingleProduct] = useState([]);

  const sliderHandler = (image) => {
    setImageSlider(image);
  };

  useEffect(() => {
    SingleDetailsProduct();
  }, [productid, userId, wishlist]);

  const SingleDetailsProduct = () => {
    singleProductApi(productid, userId, (callbackData) => {
      setSingleProduct(callbackData.data.product[0]);
      setProductId(callbackData.data.product[0].id);
      setManufacturerEmail(callbackData.data.product[0].manufacturerEmail);
      setImageSlider(callbackData.data.product[0].imagesGet[0]);
    });
    const token = localStorage.getItem("Meteor_Key");
    RecentlyViewProduct(productid, token, (callbackData) => {});
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(true);

  const handleCompare = async (item) => {
    toggleShow();
    if (userId) {
      const data = {
        productId: item.id,
        categoryId: item.categoryId,
        productSlug: item.slug,
        categorySlug: item.categorySlug,
      };
      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
      }
      dispatch(fetchCompareProducts());
    } else {
      let compareData =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (
        compareData.length > 0 &&
        compareData[0].categoryId != item.categoryId
      ) {
        toast.warning("You can only compare products from the same category.");
        return;
      }

      if (compareData.length >= 4) {
        toast.warning("You can only compare a maximum of four products.");
        return;
      }

      const existingProduct = compareData.find(
        (product) => product.productId == item.id
      );

      if (existingProduct) {
        toast.warning("This product is already added to comparison.");
        return;
      }

      compareData.push({
        productId: item.id,
        categoryId: item.categoryId,
        categorySlug: item.categorySlug,
        productSlug: item.slug,
        productImage: item.featured_image,
        productName: item?.name,
      });

      toast("Product Added");

      localStorage.setItem("comparisonProducts", JSON.stringify(compareData));
      dispatch(setCompareLength());
    }

    setChangeOffCnavas(!changeOffcanvas);
  };

  return (
    <>
      <OffCanvasExample
        show={show}
        toggleShow={toggleShow}
        handleClose={handleClose}
        changeOffcanvas={changeOffcanvas}
        setChangeOffCnavas={setChangeOffCnavas}
      />
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span>Categories</span>
          <FaAngleRight className="fs-6" />
          <span
            className="opacity-50"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/${singleProduct.categorySlug}_${singleProduct.categoryId}`
              )
            }
          >
            {singleProduct?.categoryName}
          </span>
          <FaAngleRight className="fs-6" />
          <span className="opacity-50">{singleProduct?.name}</span>
        </p>
      </div>
      <ImageSlider
        imageSlider={imageSlider}
        singleProduct={singleProduct}
        sliderHandler={sliderHandler}
        productId={productId}
        manufacturerEmail={manufacturerEmail}
        setWishlistChange={setWishlistChange}
        wishlist={wishlist}
        handleCompare={handleCompare}
      />
      <Table singleProduct={singleProduct} />
      <DetailRecentView />
      <Footer />
    </>
  );
};

export default DetailPage;
