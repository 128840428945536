import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import "styles/SupplierPortfolio.css";
import { fetchParentCategories, fetchSupplierApi } from "Apis/MainApis";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SupplierPortfolio = () => {
  const navigate = useNavigate();
  const [parentCategories, setParentCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const { categorySlug } = useParams();
  const categoryId = categorySlug?.split("_")[1] || "";

  useEffect(() => {
    feactparentcategories();
  }, [categoryId]);

  const feactparentcategories = async () => {
    fetchParentCategories((callbackData) => {
      setParentCategories(callbackData?.data?.categories);
    });
    const data = {
      categoryId: categoryId,
      page: 1,
      limit: 10,
    };
    const response = await fetchSupplierApi(data);
    setProducts(response?.data?.data?.supplierData);
  };

  const handleTabs = (id, slug) => {
    navigate(`/portfolio/${slug}_${id}`);
  };

  const handleAll = () => {
    navigate("/portfolio");
  };

  const downloadPdf = (url) => {
    window.open(url, "_blank");
    // let file = getTicketsPdf(url);
    // const blob = new Blob([file], { type: "application/pdf" });
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = "tickets.pdf";
    // document.body.appendChild(link);
    // link.click();

    // setTimeout(function () {
    //   document.body.removeChild(link);
    // }, 100);
  };
  const getTicketsPdf = async (url) => {
    let data = {};
    let name = "raul";
    await (async () => {
      const rawResponse = await axios.get(url, name, {
        method: "get",
        headers: {
          responseType: "blob",
          "Content-Type": "application/json",
        },
      });
    })();

    return data;
  };

  return (
    <>
      <NavBar />
      <div className="SupplierPortfolio ">
        <div className="header_img"></div>
        <div className="py-5">
          <div className="d-flex justify-content-center">
            <div className="PortfolioContent ">
              <h2 className="fw-bold">Our Portfolio </h2>
              <p>
                Here, you will find a curated collection of exceptional projects
                that showcase the pinnacle of creativity, innovation, and design
                excellence.
              </p>
              <div className="potfolioTabs d-flex justify-content-center ">
                <ul
                  className=" nav-pills d-flex justify-content-start align-items-center"
                  role="tablist"
                >
                  <li class="nav-item px-1" onClick={() => handleAll()}>
                    <a
                      class="nav-link"
                      data-bs-toggle="pill"
                      href="#home"
                      style={{
                        cursor: "pointer",
                        backgroundColor: categoryId == "" ? "#134a80" : "",
                        color: categoryId == "" ? "white" : "black",
                      }}
                    >
                      All
                    </a>
                  </li>
                  {parentCategories?.map((item, i) => (
                    <li
                      class="nav-item px-1"
                      onClick={() => handleTabs(item?.id, item?.slug)}
                    >
                      <a
                        class="nav-link"
                        data-bs-toggle="pill"
                        href="#home"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            categoryId == item?.id ? "#134a80" : "",
                          color: categoryId == item?.id ? "white" : "black",
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div id="home" class="row  tab-pane active">
              {products?.map((item, i) => (
                <div
                  className="col-xxl-3 col-lg-4 col-md-6 col-sm-6  mt-3"
                  key={i}
                >
                  <div className="potfolioCard text-start ">
                    <div className="images">
                      <img
                        src={item?.supplier_thumbnail_image}
                        alt="img"
                        className="w-100"
                      />
                    </div>
                    <div className="content">
                      {/* <span>{item?.categoryName}</span> */}
                      <h4 className="fw-bold m-0 py-1">
                        {item?.supplier_name}
                      </h4>
                      <p>{item?.supplier_description}</p>
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          className="Download_Portfolio"
                          onClick={() => downloadPdf(item?.supplier)}
                        >
                          Download Portfolio
                        </button>
                        <button
                          className="View_Details"
                          onClick={() => {
                            navigate(
                              `/portfolio-details/${item?.supplier_slug}_${item?.id}`,
                              window.scrollTo(0, 0)
                            );
                          }}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SupplierPortfolio;
