import { baseURl } from "Utils/BaseURL";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function allComparisonsProductsDeleteApi() {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}compare-product-delete`,
      {},
      { headers }
    );
    console.log("allComparisonsProductsDeleteApi", response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log(
      "Error in  allComparisonsProductsDelete:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchSinglePortfolio(data) {
  try {
    const response = await axios.post(`${baseURl}single-portfolio`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSinglePortfolio:", error.response.data.message);
    return null;
  }
}

export async function fetchPrivacyPolicy(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-privacy-policy`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchAboutUs:", error.response.data.message);
    return null;
  }
}

export async function fetchAboutUs(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-about-us`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchAboutUs:", error.response.data.message);
    return null;
  }
}

export async function fetchTerms(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-terms`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSupplierApi:", error.response.data.message);
    return null;
  }
}

export async function fetchSupplierApi(data) {
  try {
    const response = await axios.post(`${baseURl}fetch-suppliers`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSupplierApi:", error.response.data.message);
    return null;
  }
}

export async function addCompareProductsApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}compare-product-store`, data, {
      headers,
    });
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log(
      "Error in  addCompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function deleteCompareProductsApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}single-compare-product-delete`,
      data,
      { headers }
    );
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log(
      "Error in  deleteCompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchcompareProductsApi() {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}fetch-compare-products`,
      {},
      { headers }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(
      "Error in  fetchcompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchBlogApi(data) {
  try {
    const response = await axios.post(`${baseURl}fetch-blog-categories`, data);
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    return null;
  }
}

export async function fetchCategoryBlogApi(data) {
  try {
    const response = await axios.post(`${baseURl}fetch-category-blogs`, data);
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    return null;
  }
}

export async function fetchSingleBlogApi(data) {
  try {
    const response = await axios.post(`${baseURl}single-post`, data);
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    return null;
  }
}

export async function contactUsApi(data) {
  try {
    const response = await axios.post(`${baseURl}contact-us`, data);
    console.log(response);
    return response;
  } catch (error) {
    toast(error.response.data.message);
    console.log("Error in  ContactUsApi:", error.response.data.message);
    return null;
  }
}

export async function deleteAccountApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}delete-account`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log("Error in  deleteAccountApi:", error.response.data.message);
    return null;
  }
}

export async function changePasswordApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}password-change`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    toast(error.response.data.message);
    console.log("Error in  changePasswordApi:", error.response.data.message);
    return null;
  }
}

export const fetchVideoApi = async (data) => {
  try {
    const response = await axios.get(`${baseURl}fetch-videos`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchVideoApi:", error.response);
    return null;
  }
};

export const requestQuoteApi = async (data) => {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}request-a-quote`, data, {
      headers,
    });
    return response;
  } catch (error) {
    toast(error.response.data.message);
    console.log("Error in requestQuoteApi:", error.response);
    return null;
  }
};

export const homeProductApi = async (userId) => {
  try {
    const data = {
      uid: userId || "",
    };
    const response = await axios.post(`${baseURl}fetch-products-home`, data);
    return response;
  } catch (error) {
    console.log("Error in homeProductApi:", error.response);
    return null;
  }
};

export const fetchParentCategories = async (callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/fetch-parent-categories",
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProductAp3i = async (data, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/fetch-category-products",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const singleProductApi = async (paramid, user_id, callback) => {
  let data = JSON.stringify({
    uid: user_id || "",
    productId: paramid,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/single-product",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
      console.log("response from singleProduct", response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const featchCategoryProduct = (data, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/fetch-category-products",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      callback(response.data);
      console.log(response);
    })
    .catch((error) => {
      // toast(error.response.data.message);
      console.log(error);
    });
};

export const fetchWishListApi = async () => {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      `${baseURl}fetch-wishlist-products`,
      {},
      { headers }
    );
    return response;
  } catch (error) {
    // console.log("Error in fetchWishListApi:", error.response);
    return null;
  }
};

export const addWishlist = async (id) => {
  const token = localStorage.getItem("Meteor_Key");
  const data = {
    productId: id,
  };
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}add-to-wishlist`, data, {
      headers,
    });
    console.log(response, "addWishlist");
    return response;
  } catch (error) {
    console.log("Error in add WishList Api:", error.response);
    return null;
  }
};

export const removeWishlistApi = async (id) => {
  const token = localStorage.getItem("Meteor_Key");
  const data = {
    productId: id,
  };
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}remove-from-wishlist`, data, {
      headers,
    });
    console.log(response, "removeWishlist");
    return response;
  } catch (error) {
    console.log("Error in remove WishList Api:", error.response);
    return null;
  }
};

export const updateInfoApi = async (data) => {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}info-update`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in updateInfoApi:", error.response);
    return null;
  }
};

export async function profilePicApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}profile-update`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  profilePicApi:", error);
    return null;
  }
}

export const RecentlyViewedApi = (token, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/fetch-recently-viewed",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const RecentlyViewProduct = (id, token, callback) => {
  let data = JSON.stringify({
    productId: id,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/recently-viewed-product",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const searchApi = (uid, query, page, callback) => {
  let data = JSON.stringify({
    uid: uid,
    search: query,
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://meteoadmin.cyberx-infosystem.us/api/v1/search-product",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error);
    });
};
