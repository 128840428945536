import React, { useEffect, useState } from "react";
import NavBar from "Sections/HomeSections/NavBar";
import Footer from "Sections/HomeSections/Footer";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import ComparisonTable from "Sections/Comparison/ComparisonTable";
import AddCard from "Sections/Comparison/AddCard";
import { featchCategoryProduct } from "Apis/MainApis";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import DetailRecentView from "Sections/Detailpage/DetailRecentView";
import { singleProductApi } from "Apis/MainApis";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCompareLength } from "../../src/redux/slice/compareLengthSlice";
import { RecentlyViewedApi } from "Apis/MainApis";
import { deleteCompareProductsApi } from "Apis/MainApis";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";

const Comparison = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.data?.id);
  const { catId, pid1 } = useParams();
  const location = useLocation();
  const categoryId = catId?.split("_")[1];
  const categorySlug = catId?.split("_")[0];
  const firstProductId = pid1 ? pid1?.split("_")[1] : null;

  const [cardIndex, setCardIndex] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [products, setProducts] = useState([null, null, null, null]);
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [recentData, setRecentData] = useState([]);

  useEffect(() => {
    fetchCategory();
  }, [currentPage]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const queryParams = new URLSearchParams(location.search);
    for (let i = 1; i <= 4; i++) {
      const productId = queryParams.get(`pid${i}`);
      if (productId) {
        const [_, dynamicProductId] = productId.split("_");
        singleProductApi(dynamicProductId, userId, (callbackData) => {
          const product = callbackData.data.product[0];
          setProducts((prevProducts) => {
            const updatedProducts = [...prevProducts];
            updatedProducts[i - 1] = product;
            return updatedProducts;
          });
        });
      }
    }
  };

  const fetchCategory = () => {
    const data = {
      categoryId: categoryId,
      page: currentPage + 1,
      orderBy: "id",
      uid: userId || "",
      limit: 10,
    };
    featchCategoryProduct(data, (callbackData) => {
      setCategoryData(callbackData.data.categoryProducts);
      setPageCount(callbackData?.data?.length / 10);
      setFiltersData(callbackData.data);
    });
  };

  const setCard = (index) => {
    setCardIndex(index);
    setPopup(true);
  };

  const handleSelect = async (newId, newSlug) => {
    if (userId) {
      const data = {
        productId: newId,
        categoryId: categoryId,
        productSlug: newSlug,
        categorySlug: categorySlug,
      };
      if (cardIndex !== null) {
        if (products.some((product) => product && product.id === newId)) {
          toast.error("This product is already added");
          return;
        }
        singleProductApi(newId, userId, (callbackData) => {
          const product = callbackData.data.product[0];
          const updatedProducts = [...products];
          updatedProducts[cardIndex] = product;
          setProducts(updatedProducts);
          setPopup(false);
          setProducts(updatedProducts);
          setPopup(false);
        });
        const response = await addCompareProductsApi(data);
        console.log("response from add compare product api", response);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(`pid${cardIndex + 1}`, `${newSlug}_${newId}`);
        navigate(`${location.pathname}?${queryParams.toString()}`);
        dispatch(fetchCompareProducts());
      }
    } else {
      if (cardIndex !== null) {
        if (products.some((product) => product && product.id === newId)) {
          toast.error("This product is already added");
          return;
        }
        singleProductApi(newId, userId, (callbackData) => {
          const product = callbackData.data.product[0];
          const updatedProducts = [...products];
          updatedProducts[cardIndex] = product;
          setProducts(updatedProducts);
          setPopup(false);
          setProducts(updatedProducts);
          setPopup(false);
        });

        const comparisonProducts =
          JSON.parse(localStorage.getItem("comparisonProducts")) || [];

        comparisonProducts.push({
          categorySlug: categorySlug,
          categoryId: categoryId,
          productSlug: newSlug,
          productId: newId,
        });

        localStorage.setItem(
          "comparisonProducts",
          JSON.stringify(comparisonProducts)
        );
      }

      const queryParams = new URLSearchParams(location.search);
      queryParams.set(`pid${cardIndex + 1}`, `${newSlug}_${newId}`);
      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(setCompareLength());
    }
  };

  const handleDelete = async (index, productId) => {
    if (userId) {
      const data = {
        productId: productId,
      };
      await deleteCompareProductsApi(data);
      products[index] = null;
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(`pid${index + 1}`);
      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(fetchCompareProducts());
    } else {
      const comparisonProducts =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];
      const indexToDelete = comparisonProducts.findIndex(
        (product) => product.productId === productId
      );

      if (indexToDelete !== -1) {
        comparisonProducts.splice(indexToDelete, 1);

        localStorage.setItem(
          "comparisonProducts",
          JSON.stringify(comparisonProducts)
        );
      }

      const updatedProducts = [...products];
      updatedProducts[index] = null;
      setProducts(updatedProducts);
      products[index] = null;
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(`pid${index + 1}`);
      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(setCompareLength());
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    recentProduct();
  }, []);

  const recentProduct = () => {
    const token = localStorage.getItem("Meteor_Key");
    if (token) {
      RecentlyViewedApi(token, (callbackData) => {
        setRecentData(callbackData.data);
      });
    }
  };

  return (
    <>
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span> Categories</span>
          <FaAngleRight className="fs-6" />
          <span
            className="opacity-50"
            onClick={() =>
              navigate(`/${filtersData.categorySlug}_${filtersData.categoryId}`)
            }
            style={{ cursor: "pointer" }}
          >
            {filtersData?.category_name}
          </span>
          <FaAngleRight className="fs-6" />
          <span className="opacity-50">Comparison</span>
        </p>
      </div>
      <AddCard
        products={products}
        setPopup={setPopup}
        popup={popup}
        setCard={setCard}
        categoryData={categoryData}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        totalPageCount={totalPageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
      <ComparisonTable filtersData={filtersData} products={products} />
      <DetailRecentView recentData={recentData} recentProduct={recentProduct} />
      <Footer />
    </>
  );
};

export default Comparison;
