import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { FaRegHeart } from "react-icons/fa";
import "styles/CategoriesNavBar/FilterProduct.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FaHeart } from "react-icons/fa6";


const FilterProduct = ({
  featchProduct,
  totalPageCount,
  handlePageChange,
  handleWishlist,
  handleCompare,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="FilterProduct">
        <div className="row">
          {featchProduct?.categoryProduct?.length === 0 ? (
            <>
              <div className="d-flex align-items-center">
                <h2>No items to show</h2>
              </div>
            </>
          ) : (
            <>
              {featchProduct?.categoryProducts?.map((item, i) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-3"  key={i}>
                    <div className="FilterProduct-card ">
                      <img
                        src={item.featured_image}
                        class="card-img-top filter_image"
                        alt="..."
                      />
                      <div className="FilterProduct-details">
                        <div className="d-flex justify-content-between align-items-center FilterProduct-details-heart">
                          <p className="m-0 text-start filter_text">
                            {item.name}
                          </p>
                          {item.wishlist === "no" ? (
                            <>
                              <FaRegHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <FaHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          )}
                        </div>
                        <p className="text-start filter_text">
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="global_button_white"
                            onClick={() => {
                              navigate(
                                `/detail-page/${item?.slug}_${item?.id}`,
                                window.scrollTo(0, 0)
                              );
                            }}
                          >
                            Details
                          </button>
                          <button
                            className="global_button_colored"
                            onClick={() => handleCompare(item)}
                          >
                            Compare
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <div className="category_paginate">
        {totalPageCount > 1 && (
          <ReactPaginate
            key={totalPageCount}
            pageCount={totalPageCount}
            breakLabel={"..."}
            pageRangeDisplayed={1} //... ke bech me pages
            marginPagesDisplayed={1} //first and last pages ...
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-page"}
            previousLabel={"previous"}
            nextLabel={"next"}
          />
        )}
      </div>
    </>
  );
};

export default FilterProduct;
