import React from "react";
import "../../styles/Comparison/ComparisonTable.css";

const ComparisonTable = ({ filtersData, products }) => {

  return (
    <>
      <div className="ComparisonTable">
        <table style={{ width: "100%" }}>
          <tr>
            <th colspan="12" className="Comparison">
              Comparison
            </th>
          </tr>
          {filtersData?.filters?.map((item, i) => (
            <tr>
              <td className="fw-bold">{item?.filter_name}: </td>
              <td>{products[0]?.[item?.filter_column]||'-'}</td>
              <td>{products[1]?.[item?.filter_column]||'-'}</td>
              <td>{products[2]?.[item?.filter_column]||'-'}</td>
              <td>{products[3]?.[item?.filter_column]||'-'}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default ComparisonTable;
