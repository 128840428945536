import React, { useEffect, useState } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import NewsSidebar from "./NewsSidebar.jsx";
import NewsRIghtSec from "./NewsRIghtSec";
import "../../styles/News.css";
import {
  fetchBlogApi,
  fetchCategoryBlogApi,
  fetchSingleBlogApi,
} from "Apis/MainApis.js";
import { useParams } from "react-router-dom";

const News = () => {
  const { categorySlug } = useParams();
  const categoryId = categorySlug ? categorySlug.split("_")[1] : "";
  const [categoriesBlog, setCategoriesBlog] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [recentPost, setRecentPost] = useState([]);
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  console.log("categoriesdataaaaaaaaaaaa", recentPost);

  useEffect(() => {
    async function fetchBlogCategories() {
      const response = await fetchBlogApi();
      setCategoriesBlog(response?.data?.data?.categories);
      setRecentPost(response?.data?.data?.recentPosts);
    }
    fetchBlogCategories();
  }, []);

  useEffect(() => {
    async function fetchCategoriesData() {
      const data = {
        categoryId: categoryId,
        page: currentPage + 1,
        limit: 10,
      };
      const response = await fetchCategoryBlogApi(data);
      setCategoriesData(response?.data?.data?.categoryPosts);
      setPageCount(Math.ceil(response?.data?.data?.length / 10));
    }
    fetchCategoriesData();
  }, [categoryId]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <NavBar />
      <div className="NewsSec">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <NewsSidebar
              categoriesBlog={categoriesBlog}
              categoryId={categoryId}
              recentPost={recentPost}
            />
          </div>
          <div className="col-lg-9 col-md-8">
            <NewsRIghtSec
              categoriesData={categoriesData}
              totalPageCount={totalPageCount}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
