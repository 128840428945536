import React, { useState, useEffect } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import "styles/PrivacyPolicy.css";
import { fetchPrivacyPolicy } from "Apis/MainApis";

const PrivacyPolicy = () => {
  const [terms, setTerms] = useState([]);
  const [termsHeading, setTermsHeading] = useState("");

  useEffect(() => {
    async function fetchterms() {
      const response = await fetchPrivacyPolicy();
      setTerms(response?.data?.data?.data[0]?.about_desc);
      setTermsHeading(response?.data?.data?.data[0]?.title);
    }
    fetchterms();
  }, []);
  return (
    <>
      <NavBar />
      <div className="PrivacyPolicybg-body">
        <div className="policy_header d-flex justify-content-center align-items-center flex-column">
          <div className="policy_header_content">
            <h1 className="fw-bold text-white">Privacy Policy</h1>
            {/* <p className="fw-bold opacity-75 text-white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
            </p> */}
          </div>
        </div>
        <div className="policy_content ">
          {/* <p>
            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit..."
          </p> */}
          <h2>{termsHeading}</h2>
          <div dangerouslySetInnerHTML={{ __html: terms }}></div>
          {/* <h2>Why do we use it?</h2>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose injected humour
            and the like.
          </p>
          <h2>Where does it come from?</h2>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word in classical literature,
            discovered the undoubtable source. Lorem Ipsum comes from sections
            1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
            of Good and Evil) by Cicero, written in 45 BC. This book is a
            treatise on the theory of ethics, very popular during the
            Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
            amet..", comes from a line in section 1.10.32
          </p>
          <h2>Where can I get some?</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc
          </p> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
