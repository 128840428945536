import React from "react";
import "styles/NewsRIghtSec.css";
import newsProductImg from "../../Public/filterProduct_img/filterproduct_image.png";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FilterProduct } from "Sections/CategoriesSections/FilterProduct";
import ReactPaginate from "react-paginate";

const NewsRIghtSec = ({ categoriesData, totalPageCount, handlePageChange }) => {
  const navigate = useNavigate();

  const handleSingleBlog = (postSlug, postId) => {
    navigate(`/news-detail/${postSlug}_${postId}`);
  };
  return (
    <div className="NewsRIghtSec">
      <div className="row">
        {categoriesData === undefined || categoriesData?.length === 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <h2>No data found</h2>
            </div>
          </>
        ) : (
          <>
            {categoriesData?.map((category, i) => {
              return (
                <div className="col-xxl-4 col-md-6 mt-4 px-3" key={i}>
                  <div className="productCard">
                    <div className="images">
                      <img src={newsProductImg} alt="img" />
                    </div>
                    <div className="productContent pt-3 text-start ">
                      <div className="d-flex flex-wrap align-items-center gap-4 ">
                        <div className="d-flex align-items-center gap-1 icons_Sec">
                          <FaRegCalendarAlt className="i" />
                          <span>{category?.date}</span>
                        </div>
                        {/* <div className="d-flex align-items-center gap-1 icons_Sec">
                      <IoEyeSharp className="i" />
                      <span>290 Views</span>
                    </div> */}
                      </div>
                      <h5 className="fw-bold m-0 py-2">
                        {category?.post_title}
                      </h5>
                      <p className="m-0 newsRightsecText">
                        {category?.post_description}
                      </p>
                      <Link
                        to=""
                        className="d-flex align-items-center gap-2 text-decoration-none "
                        onClick={() =>
                          handleSingleBlog(category?.post_slug, category?.id)
                        }
                      >
                        <span onClick={() => navigate("/newsdetails")}>
                          Read More
                        </span>{" "}
                        <FaAngleDoubleRight />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="category_paginate">
        {totalPageCount > 1 && (
          <ReactPaginate
            key={totalPageCount}
            pageCount={totalPageCount}
            breakLabel={"..."}
            pageRangeDisplayed={1} //... ke bech me pages
            marginPagesDisplayed={1} //first and last pages ...
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-page"}
            previousLabel={"previous"}
            nextLabel={"next"}
          />
        )}
      </div>
    </div>
  );
};

export default NewsRIghtSec;
