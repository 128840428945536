import { baseURl } from "Utils/BaseURL";
import axios from "axios";
import React from "react";

export const fetchUserApi = async () => {
  const token = localStorage.getItem("Meteor_Key");
  // console.log("ytttttttttttttt", token);
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${baseURl}fetch-user`, { headers });
    // console.log(response);
    return response;
  } catch (error) {
    // console.log("Error in newPasswordApi:", error.response);

    return null;
  }
};




