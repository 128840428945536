import React, { useEffect, useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
import "../../styles/Profile/ProfileSideBar.css";
import { MdLogout } from "react-icons/md";
import Wishlist from "../../Pages/Wishlist";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchUser } from "../../redux/slice/fetchUserSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { profilePicApi } from "Apis/MainApis";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "styles/Profile/DeleteAccountModal";
import { BiMenuAltLeft } from "react-icons/bi";
import { fetchWishlist } from "../../redux/slice/fetchWishlistSlice";

const ProfileSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const fileInputRef = React.createRef();

  const username = useSelector((state) => state?.user?.data);
  const handlerLogout = async () => {
    localStorage.clear();
    navigate("/");
    await dispatch(fetchUser());
    await dispatch(fetchWishlist());
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  async function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/png")) {
        toast.error("Image format should be PNG");
        return;
      }
      try {
        const base64String = await getBase64(file);
        // console.log(base64String);

        const data = {
          profile: base64String,
        };

        const response = await profilePicApi(data);
        // console.log(response);
        await dispatch(fetchUser());
      } catch (error) {
        console.error("Error during image upload:", error);
      }
    }
  }
  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  return (
    <>
      <ChangePasswordModal
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
      />
      <DeleteAccountModal
        showDeleteAccount={showDeleteAccount}
        setShowDeleteAccount={setShowDeleteAccount}
      />

      <div className="desktopProfile">
        <div className="myaccountleftbar">
          <div className="Image d-flex justify-content-center align-items-center mb-3">
            <img
              src={
                username?.image
                  ? username?.image
                  : "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707523200&semt=ais"
              }
              alt="image"
            />
          </div>
          <h6 className="text-start fw-bold">{username.name}</h6>
          <h6 className="text-start fw-bold">{username.email}</h6>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
          />

          <button
            className="uploadImageButton mb-3"
            onClick={handleUploadButtonClick}
          >
            Upload image
          </button>

          <div className="myaccountleftbarbox2 d-flex">
            <div>
              <IoPersonOutline
                style={{ color: "#134a80", fontSize: "larger" }}
              />
            </div>
            <div className="ms-3">
              <ul className="account_setting text-start m-0 p-0">
                <li
                  style={{ color: "black", fontWeight: "bold" }}
                  className="mb-1"
                >
                  My Account
                </li>
                <li>My Profile</li>
                <li onClick={() => setShowResetPassword(true)}>
                  Change Password
                </li>
                <li onClick={() => setShowDeleteAccount(true)}>
                  Delete Account
                </li>
                <li onClick={() => navigate("/wishlist")}>My Wishlist</li>
              </ul>
            </div>
          </div>

          <div className="myaccountleftbarbox5 d-flex">
            <div>
              <MdLogout style={{ color: "#134a80", fontSize: "larger" }} />
            </div>
            <div className="ms-3">
              <ul className="account_setting text-start m-0 p-0">
                <li
                  style={{ color: "black", fontWeight: "bold" }}
                  onClick={handlerLogout}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile_profile">
        <div class="offcanvas offcanvas-start " id="mobileProfile">
          <div className="myaccountleftbar">
            <div className="d-flex justify-content-between">
              <div className="Image d-flex justify-content-center align-items-center mb-3">
                <img
                  src={
                    username?.image
                      ? username?.image
                      : "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707523200&semt=ais"
                  }
                  alt="image"
                />
              </div>
              <button
                type="button"
                class="btn-close btn-close-dark fw-bold"
                data-bs-dismiss="offcanvas"
              ></button>
            </div>
            <h6 className="text-start fw-bold">{username.name}</h6>
            <h6 className="text-start fw-bold">{username.email}</h6>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            <button
              className="uploadImageButton mb-3"
              onClick={handleUploadButtonClick}
            >
              Upload image
            </button>

            <div className="myaccountleftbarbox2 d-flex">
              <div>
                <IoPersonOutline
                  style={{ color: "#134a80", fontSize: "larger" }}
                />
              </div>
              <div className="ms-3">
                <ul className="account_setting text-start m-0 p-0">
                  <li
                    style={{ color: "black", fontWeight: "bold" }}
                    className="mb-1"
                  >
                    My Account
                  </li>
                  <li>My Profile</li>
                  <li onClick={() => setShowResetPassword(true)}>
                    Change Password
                  </li>
                  <li onClick={() => setShowDeleteAccount(true)}>
                    Delete Account
                  </li>
                  <li onClick={() => navigate("/wishlist")}>My Wishlist</li>
                </ul>
              </div>
            </div>

            <div className="myaccountleftbarbox5 d-flex">
              <div>
                <MdLogout style={{ color: "#134a80", fontSize: "larger" }} />
              </div>
              <div className="ms-3">
                <ul className="account_setting text-start m-0 p-0">
                  <li
                    style={{ color: "black", fontWeight: "bold" }}
                    onClick={handlerLogout}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* mobile Profile Toggle.......................................... */}
        <BiMenuAltLeft
          className="fs-1 d-flex justify-content-start"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#mobileProfile"
        />
      </div>
    </>
  );
};

export default ProfileSideBar;
