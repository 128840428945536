import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchcompareProductsApi } from "Apis/MainApis";

export const fetchCompareProducts = createAsyncThunk(
  "fetchCompareProducts",
  async (userId) => {
    try {
      const response = await fetchcompareProductsApi();
      // console.log("response from fetchCompareProducts", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.log("Error fetching user:", error.response);
      return null;
    }
  }
);

const compareProductsSlice = createSlice({
  name: "compareProducts",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompareProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompareProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCompareProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export default compareProductsSlice.reducer;
