import React, { useState } from "react";
import "styles/Forms/Forgot.css";
import logo from "Public/navBar_images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURl } from "Utils/BaseURL";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [forgot, setForgot] = useState({
    username: "",
  });

  const [emailError, setEmailError] = useState("");

  const emailHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForgot({ ...forgot, [name]: value });
  };

  const forgotHandler = async (e) => {
    e.preventDefault();

    if (forgot.username === "") {
      setEmailError("Email can't be  empty!");
      return;
    }

    try {
      const forgotresponse = await axios.post(
        `${baseURl}forgot-password`,
        forgot
      );
      if (forgotresponse.data.status === "success") {
        navigate("/otp")
        toast.success(forgotresponse?.data?.message);
      } else {
        toast.success(forgotresponse?.data?.message);
      }
     
    } catch (error) {
      console.log("Error");
      toast.warning(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="container-fluid  ">
        <div className="row ForgotPassword d-flex justify-content-center align-items-center">
          <div className="col-md-5 d-flex  align-items-center">
            <div className="Forgot_form">
              <div className="card">
                <div class="d-flex justify-content-center align-items-center pb-3">
                  <div class="d-flex justify-content-center align-items-center  avtar ">
                    <img src={logo} alt="img" onClick={() => navigate("/")} />
                  </div>
                </div>
                <h4>Forgot - Password</h4>
                <form onSubmit={forgotHandler}>
                  <label for="email">Email address*</label>
                  <input
                    type="email"
                    name="username"
                    placeholder="Enter your email"
                    value={forgot.username}
                    onChange={emailHandler}
                  />
                  {emailError ? (
                    <span className="text-danger text-start">{emailError}</span>
                  ) : (
                    ""
                  )}
                  <button type="submit" className="forgotpassword_btn">
                    Forgot Password 
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
