import React from "react";
import "styles/Footer.css";
import { FaSquareFacebook, FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import footer_logo from "../../Public/FooterLogo/footer_logo.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div>
        <footer>
          <img src={footer_logo} alt="img" onClick={() => navigate("/")} />
          <p className="m-0 py-3">
            Disclaimer : Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum
            passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </p>
          <div className="otherPages ">
            <Link
              to="/portfolio"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">Supplier Portfolio</p>
            </Link>

            <Link
              to="/news"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0  text-center">Industry News</p>
            </Link>

            <Link
              to="/aboutus"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">About us</p>
            </Link>

            <Link
              to="/contactus"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">Contact Us</p>
            </Link>

            <Link
              to="/terms"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">Terms of use</p>
            </Link>

            <Link
              to="/privacypolicy"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">Privacy Policy</p>
            </Link>

            <Link
              to="/news"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center">News</p>
            </Link>
            <Link
              to="/videos"
              className="text-decoration-none"
              onClick={scrollToTop}
            >
              <p className="m-0 text-center" id="video_sec">
                Videos
              </p>
            </Link>
          </div>
          <hr className="footer_hrLine" />
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-6 text-start">
              <p className="m-0">
                © Copyright 2024 MeteoCompare, Inc. All rights reserved.
              </p>
            </div>
            <div className="col-md-6 d-flex justify-content-end gap-3">
              <Link to="https://www.facebook.com/">
                <FaSquareFacebook className="social_media" />
              </Link>
              <Link to="https://www.instagram.com/accounts/login/">
                <FaInstagramSquare className="social_media" />
              </Link>
              <Link to="https://twitter.com/i/flow/login">
                <FaSquareXTwitter className="social_media" />
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
