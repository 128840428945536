import React from "react";
import "../../styles/Comparison/ModalPopup.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const ModalPopup = ({
  setPopup,
  popup,
  categoryData,
  handleSelect,
  totalPageCount,
  handlePageChange,
  currentPage,
}) => {
  return (
    <>
      <Modal
        show={popup}
        onHide={() => setPopup(false)}
        size="xl"
        scrollable="true"
      >
        <Modal.Header closeButton className="modalpopup_title">
          Choose Products
        </Modal.Header>
        <Modal.Body>
          <div class="container">
            <div class="row g-4">
              {categoryData?.map((item, i) => (
                <div className="col-lg-4 ">
                  <div className="modalpopup-card d-flex align-items-center flex-column justify-content-center">
                    <img
                      src={item.featured_image}
                      alt="img"
                      class="modalpopup_image"
                    />
                    <div className="modalpopup-details">
                      <div className="d-flex justify-content-between align-items-center modalpopup-details-heart">
                        <p className="m-0 text-start modalpopup_text">
                          {item.name}
                        </p>
                      </div>
                      <p className="text-start modalpopup_text m-0">
                        {item.description}
                      </p>
                      <button
                        onClick={() => handleSelect(item.id, item.slug)}
                        className="modalpopup_button"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="category_paginate">
            {totalPageCount > 1 && (
              <ReactPaginate
                key={totalPageCount}
                pageCount={totalPageCount}
                breakLabel={"..."}
                pageRangeDisplayed={1} //... ke bech me pages
                marginPagesDisplayed={1} //first and last pages ...
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active-page"}
                previousLabel={"previous"}
                nextLabel={"next"}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPopup;
