import React, { useState } from "react";
import "styles/DetailPage/Table.css";

const Table = ({ singleProduct }) => {
  return (
    <>
      <div className="Detail_page_Table">
        <table>
          <tbody>
            <tr>
              <th className="Detail_page_Table_heading">KEY ADVANTAGES</th>
            </tr>
            {singleProduct?.advantages?.map((data, i) => {
              return (
                <tr key={i}>
                  <td>{data.advantage}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="mt-4">
          <tbody>
            <tr>
              <th className="Detail_page_Table_heading">Specifications</th>
            </tr>
            {singleProduct?.specifications?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item?.title}: {item?.desc}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
